/**
 * App.tsx
 *
 * This file contains the main React component for the Yakoa Coding Challenge.
 * It sets up the overall layout and design of the application.
 *
 * The primary component rendered within this file is the Display component,
 * which is responsible for rendering and interacting with NFT images and their
 * corresponding masks.
 *
 * You are responsible for implementing this component.
 */

import React from "react";
import Display from "./component/Display";
import YakoaLogo from "assets/yakoa-logo.png";

function App() {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      padding: "80px 80px 32px 80px",
      boxSizing: "border-box",
      gap: "32px",
      width: "100vw",
      height: "100vh",
      overflow: "hidden",
      justifyContent: "space-between",
      backgroundColor: "#FAFBFF",
    }}>
      {/* TODO: Implement this component */}
      {/* <Display imageWidth={320} />*/}
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "6px",
        width: "100%",
      }}>
        <img src={YakoaLogo} width={"114px"} height={"20px"} alt={"Yakoa"} />
        <div style={{fontSize: 12}}>Coding challenge</div>
        <Display alt="Image here" />
      </div>
    </div>
  )
}

export default App
