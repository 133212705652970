import React from "react";
import "./style.css";

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  alt: string;
  src: string;
  maskImage: string;
}

export const Image: React.FC<Props> = ({ src, alt, maskImage }) => {
  return (
    <div className="image-container">
      <img src={src} className="imageContainer" alt={alt} />
      {maskImage && <img className="overlay imageContainer" src={maskImage} />}
    </div>
  );
};
