export default function getImageArrayBuffer(imageUrl: string): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const arrayBuffer = reader.result;

          resolve(arrayBuffer);
        };
        reader.readAsArrayBuffer(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
