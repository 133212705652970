/**
 * utils.ts
 *
 * This file contains utility functions for handling images and their associated data.
 * The primary use case for these functions is to manipulate NFT images and their segmentations.
 * Functions in this module include extracting properties from PNG files, getting pixel data,
 * and creating masks for specific regions.
 */

// Imports images and their segmentations
import Image1 from "assets/nft-images/1.png";
import Segmentation1 from "assets/nft-segmentations/1.png";
import Image2 from "assets/nft-images/2.png";
import Segmentation2 from "assets/nft-segmentations/2.png";
import Image3 from "assets/nft-images/3.png";
import Segmentation3 from "assets/nft-segmentations/3.png";
import Image4 from "assets/nft-images/4.png";
import Segmentation4 from "assets/nft-segmentations/4.png";
import Image5 from "assets/nft-images/5.png";
import Segmentation5 from "assets/nft-segmentations/5.png";

/**
 * imageOptions contains an array of objects, each representing an NFT image and its segmentation.
 * Each object has a name, image, and segmentation property.
 */
export const imageOptions = [
  { name: "Pattern 1", image: Image1, segmentation: Segmentation1 },
  { name: "Pattern 2", image: Image2, segmentation: Segmentation2 },
  { name: "Pattern 3", image: Image3, segmentation: Segmentation3 },
  { name: "Pattern 4", image: Image4, segmentation: Segmentation4 },
  { name: "Pattern 5", image: Image5, segmentation: Segmentation5 },
];

/**
 * Extracts properties from a PNG ArrayBuffer.
 *
 * Only works with specially-formatted PNG files that contain the necessary 'tEXt' chunks.
 * Stick to the images from assets/nft-segmentations with this function unless you plan to
 * reverse-engineer the property encoding (not recommended)
 *
 * This function reads through the chunks of the PNG file and looks for 'tEXt' chunks.
 * The 'properties' stored in these chunks are then parsed and returned as an array
 * of objects containing the extracted properties (type, value, region).
 *
 * @param arrayBuffer The ArrayBuffer containing the PNG data.
 * @returns An array of objects containing the extracted properties (type, value, region).
 */
export const extractPropertiesFromPng = (
  arrayBuffer: ArrayBuffer
): { type: string; value: string; region: number }[] => {
  const dataView = new DataView(arrayBuffer);
  const textData: Record<string, string> = {};

  let offset = 8; // Skip the PNG signature

  while (offset < dataView.byteLength) {
    const length = dataView.getUint32(offset);
    offset += 4;

    const chunkType = String.fromCharCode.apply(
      null,
      Array.from(new Uint8Array(arrayBuffer, offset, 4))
    );
    offset += 4;

    if (chunkType === "tEXt") {
      const chunkData = new Uint8Array(arrayBuffer, offset, length);
      const nullIndex = chunkData.indexOf(0);
      const keyword = String.fromCharCode.apply(
        null,
        Array.from(chunkData.subarray(0, nullIndex))
      );
      textData[keyword] = String.fromCharCode.apply(
        null,
        Array.from(chunkData.subarray(nullIndex + 1))
      );
    }

    offset += length + 4; // Move to the next chunk (skip the CRC-32 field)
  }

  return JSON.parse(textData.properties).map(
    (property: { t: string; v: string; r: number }) => {
      return {
        type: property.t,
        value: property.v,
        region: property.r,
      };
    }
  );
};

/**
 * Gets the pixel data from an HTMLImageElement.
 *
 * This function creates a canvas and draws the image onto it, then extracts the pixel
 * data from the canvas context. It returns the ImageData containing the pixel data,
 * or undefined if the context is not available.
 *
 * @param img The HTMLImageElement to extract pixel data from.
 * @returns The ImageData containing the pixel data, or undefined if the context is not available.
 */
export const getPixelData = (img: HTMLImageElement): ImageData | undefined => {
  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext("2d");
  ctx?.drawImage(img, 0, 0);
  return ctx?.getImageData(0, 0, canvas.width, canvas.height);
};

/**
 * Creates a mask for a specific region based on the provided segmentation data.
 *
 * This function generates a new image that highlights the specified region by masking
 * out all other regions. It creates a canvas with the input image dimensions, and then
 * iterates over the segmentation data, setting the alpha channel to 0 for the specified
 * region and 255 for all other regions. The resulting mask is returned as a data URL.
 *
 * @param regionId The ID of the region for which to create the mask.
 * @param segData The Uint8ClampedArray containing the segmentation data.
 * @param inputWidth The width of the input image.
 * @param inputHeight The height of the input image.
 * @returns A data URL for the generated mask.
 */
export const createMaskForRegion = (
  regionId: number,
  segData: Uint8ClampedArray,
  inputWidth: number,
  inputHeight: number
) => {
  const canvas = document.createElement("canvas");
  canvas.width = inputWidth;
  canvas.height = inputHeight;
  const ctx = canvas.getContext("2d");
  const data = ctx?.createImageData(inputWidth, inputHeight);
  const maskData = new Uint8ClampedArray(segData);
  for (let i = 0; i < segData.length; i += 4) {
    maskData[i] = 250;
    maskData[i + 1] = 251;
    maskData[i + 2] = 255;
    if (regionId === segData[i]) {
      maskData[i + 3] = 0;
    } else {
      maskData[i + 3] = 255;
    }
  }
  if (data) {
    data.data.set(maskData);
    ctx?.putImageData(data, 0, 0);
  }
  return canvas.toDataURL("image/png");
};
