import { FunctionComponent } from "react";
import { IattributesData, IimageOptions } from "types/types";

interface Props {
  hoverHandler: (id: number) => void;
  counter: number | string;
  attributes: IattributesData;
}

const Attribute: FunctionComponent<Props> = ({
  hoverHandler,
  counter,
  attributes,
}) => {
  return (
    <div className="flexColumnContainer maxHeightWidth">
      <div className="parameter">Parameters</div>
      <div className="gridContainer">
        {attributes &&
          attributes.map((attribute) => {
            return (
              <div
                key={attribute.type}
                className="attributes"
                style={{
                  backgroundColor:
                    counter == attribute.region ? "#F4F5FD" : "#fafbff",
                }}
                onMouseEnter={() => hoverHandler(attribute.region)}
              >
                <p>{attribute.type}</p>
                <p>{attribute.value}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Attribute;
