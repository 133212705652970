import { FunctionComponent, useState, useEffect } from "react";
import { Image as ImageComponent } from "./Image";
import Attribute from "./Attribute";
import { IattributesData, IimageOptions } from "types/types";
import {
  createMaskForRegion,
  extractPropertiesFromPng,
  getPixelData,
} from "utils";
import getImageArrayBuffer from "helper/imageToBuffer";
import { imageOptions } from "utils";
import Dropdown from "./UI/Dropdown";

interface Props {
  alt: string;
}

const Display: FunctionComponent<Props> = ({ alt }) => {
  const [value, setValue] = useState<number>(1);
  const [maskImage, setMaskImage] = useState<string>("");
  const [counter, setCounter] = useState<string>("2");

  const [nftImageOptions, setNftImageOptions] = useState<IimageOptions[]>([
    {
      label: "",
      value: 0,
      nftSrc: "",
      segmentationSrc: "",
      attributeData: [{ type: "", value: "", region: 0 }],
    },
  ]);

  useEffect(() => {
    getImageData();
  }, []);

  const handleValueChange = (val: number) => {
    setValue(val);
  };

  const getImageData = async () => {
    try {
      let imageObjectList = nftImageOptions;
      for (let i: number = 1; i <= 5; i++) {
        const arrayBuffer = await getImageArrayBuffer(
          imageOptions[i - 1].segmentation
        );

        const currentImageProperties: IattributesData =
          extractPropertiesFromPng(arrayBuffer);

        const imageOptionObject: IimageOptions = {
          label: imageOptions[i - 1].name,
          value: i,
          nftSrc: imageOptions[i - 1].image,
          segmentationSrc: imageOptions[i - 1].segmentation,
          attributeData: currentImageProperties,
        };

        imageObjectList =
          i == 1
            ? [imageOptionObject]
            : [...imageObjectList, imageOptionObject];
      }
      setNftImageOptions(imageObjectList);
    } catch (err) {
      console.error(`Failed to load image: ${err}`);
    }
  };

  const onHoverHandler = (id: number) => {
    const segmantationImage = imageOptions[value - 1].segmentation;
    const img: HTMLImageElement = new Image() as HTMLImageElement;
    img.src = segmantationImage;
    if (img.width > 0) {
      const pixelData: any = getPixelData(img);

      const getMask = createMaskForRegion(
        id,
        pixelData?.data,
        pixelData?.width,
        pixelData?.height
      );
      setMaskImage(getMask);
      setCounter(`${id}`);
    }
  };

  return (
    <div className="flexColumnContainer">
      <div className="flexRowContainer  spacing">
        <h1>Image Trait Display</h1>
        <Dropdown options={nftImageOptions} changeHandler={handleValueChange} />
      </div>

      <div className="flexRowContainer paddingClass spacing">
        <ImageComponent
          src={nftImageOptions[value - 1].nftSrc}
          alt={alt}
          maskImage={maskImage}
        />
        {nftImageOptions.length > 0 && (
          <Attribute
            attributes={nftImageOptions[value - 1].attributeData}
            counter={counter}
            hoverHandler={onHoverHandler}
          />
        )}
      </div>
    </div>
  );
};

export default Display;
