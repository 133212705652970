import { FC, ChangeEvent } from "react";

type Option = {
  value: number;
  label: string;
};

type Props = {
  options: Option[];
  changeHandler: (value: number) => void;
};

const Dropdown: FC<Props> = ({ options, changeHandler }) => {
  const handleValueChange = (e: ChangeEvent<HTMLSelectElement>) => {
    changeHandler(Number(e.target.value));
  };

  return (
    <select className="select" onChange={handleValueChange}>
      {options.map((item, i) => (
        <option className="optionClass" key={i} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );
};

export default Dropdown;
